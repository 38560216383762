import React, {ReactElement} from 'react';
import {Icon, Label, List} from 'semantic-ui-react';
import {asLink} from '../../AsLink';
import {useSortedGroupMembers} from '../../store/GroupMembers';
import {useGroup} from '../../store/Groups';

export default function Members(): ReactElement {
  const {completedRoundsCount} = useGroup() || {};
  const groupMembers = useSortedGroupMembers();

  return (
    <section>
      {groupMembers.length > 0 && (
        <div className=''>
          <List divided relaxed>
            {groupMembers.map(
              ({id, name, pointBalance = 0, pointDiffToTopPlayer = 0, roundsCount = 0, euroBalance, isYou}) => (
                <List.Item as={asLink(`member/${id}`)} key={id}>
                  <List.Icon color={'black'} size={'large'} name='user' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{name}</List.Header>
                    <List.Description>
                      <Label size={'small'} color={pointBalance >= 0 ? 'green' : 'red'}>
                        {pointBalance} <Icon name='sort' />
                      </Label>
                      {typeof euroBalance !== 'number' && (
                        <Label size={'small'} color={'yellow'}>
                          {pointDiffToTopPlayer} <Icon name='bullseye' />
                        </Label>
                      )}
                      {typeof euroBalance === 'number' && (
                        <Label size={'small'} color={'blue'}>
                          {euroBalance.toFixed(2)} <Icon name='euro sign' />
                        </Label>
                      )}
                      <Label size={'small'} color={'orange'}>
                        {roundsCount} /{' '}
                        {completedRoundsCount ? Math.ceil((roundsCount / completedRoundsCount) * 100) : 0}%{' '}
                        <Icon name='time' />
                      </Label>
                      {!!isYou && (
                        <Label size={'small'} color={'teal'}>
                          du <Icon name='linkify' />
                        </Label>
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ),
            )}
          </List>
        </div>
      )}
    </section>
  );
}
